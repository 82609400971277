<h1 mat-dialog-title>{{team.name}}</h1>
<div mat-dialog-content>
  @if (showChangeTeamName) {
  <div>
    <mat-form-field>
      <mat-label>Nome squadra</mat-label>
      <input
        matInput
        name="name"
        placeholder="Nome"
        required
        [(ngModel)]="team.name"
      >
    </mat-form-field>
  </div>
  }

  <div
    accept="image/*"
    class="my-drop-zone"
    ngfDrop
    [ngClass]="{'good-file-over':validComboDrag, 'bad-file-over':invalidComboDrag}"
    selectable
    [(file)]="file"
    [(invalidDrag)]="invalidComboDrag"
    [(validDrag)]="validComboDrag"
  >
    <p>Clicca o trascina il file quì per caricare l'immagine</p>
    @if (file) {
    <div @createBox>
      <img
        alt="uploadImage"
        [ngfSrc]="file"
      />
    </div>
    }
  </div>
  <div>
    <app-notification-subscription
      label="Notifiche tramite email"
      type="email"
      [team]="team"
      [(subscriptions)]="team.email_notification_subscriptions"
    />
    <app-notification-subscription
      label="Notifiche push"
      type="push"
      [team]="team"
      [(subscriptions)]="team.push_notification_subscriptions"
    />
  </div>
</div>
<div
  align="end"
  mat-dialog-actions
>
  <button
    mat-button
    tabindex="-1"
    type="reset"
    [mat-dialog-close]="undefined"
  >ANNULLA</button>
  <button
    cdkFocusInitial
    mat-button
    type="submit"
    (click)="save()"
  >SALVA</button>
</div>